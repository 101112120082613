import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import styled from "styled-components";
import Seo from "../components/SEO";
import {StaticImage} from "gatsby-plugin-image";
import TurnyrasConstant from "../constants/turnyras";
import {Link} from "gatsby"
import {ButtonA} from "../styles/Button";

const TournamentPage = () => {
    return (
        <Layout>
            <Seo title="Cornhole Turnyrai ir Renginiai - Bohabo Cornhole"
                 description="Čia rasite visą informaciją apie Bohabo organizuojamus Cornhole žaidimo turnyrus ir kitus renginius. Cornhole turnyrai - puikus būdas išbandyti savo jėgas!"
            />
            <PageWrapper>
                <PageContainer>

                    <TurnyrasConstant/>

                    <RulesInfoWrapper>
                        <RulesText>
                            <h2>#3 Bohabo Cornhole žaidimo turnyras 🏆 </h2>
                            <p><b>Gegužės 21 d.</b> kviečiame visus į jau trečią kartą organizuojamą Bohabo Cornhole žaidimo turnyrą. Praėjusiame turnyre dalyvavo net 52 dalyviai, šiame turnyre sieksime suburti dar didesnį dalyvių skaičių!</p>

                            <p>Turnyro nugalėtojams išdalinsime rėmėjų įsteigtus prizus, kurių bendra vertė siekia net <b>500€!!!</b></p>

                            <p>Bohabo Cornhole žaidimas nereikalauja didelių įgūdžių, todėl yra laukiami visi: žaidimo naujokai, entuziastai ir tie, kurie niekada nėra bandę ir nori išbandyti šį azartišką ir įtraukiantį žaidimą.</p>

                            <p>Cornhole – ypač populiarus lauko žaidimas Jungtinėse Amerikos Valstijose, kur organizuojami aukščiausio lygio turnyrai. Trumpai apie žaidimą: žaidimo lenta pastatoma 8 m. atstumu nuo žaidimo linijos, nuo kurios bus metami specialūs žaidimui pritaikyti maišeliai. Užmetus maišelį ant lentos skiriamas 1 taškas, maišeliui įkritus į skylę – 3 taškai, jeigu maišelis nukrenta ant žemės arba dalis jo liečia žemę – 0 taškų. Žaidimas baigiasi surinkus 11 arba 21 tašką.</p>


                            <h4>Apie turnyrą</h4>

                            <p><b>KADA:</b> 2022 m. gegužės 21 d. (šeštadienis). Pradžia 14:00 val. <br/>
                                <b>KUR:</b> Kauno futbolo maniežas, Aukštaičių g. 51, Kaunas</p>

                            <h4>Dalyvių registracija</h4>

                            <p>Registracija vyksta iki <b>Gegužės 21 d.</b><br/>
                            <b>Dalyvio mokestis 15 €</b>. Pastaba: dalyvio registracija užbaigiama pervedus dalyvio mokestį.</p>

                            <h4>Registracijos forma</h4>
                            <a href="https://forms.gle/Ns1dA1v8sbwz5Dgm9" target="_blank" rel="noreferrer" big="true">Spauskite čia norėdami užsiregistruoti į turnyrą</a>

                            <h4>Turnyro dalyviai</h4>
                            <p>Turnyre gali dalyvauti visi norintys. Turnyro metu bus žaidžiama <b>1 x 1 formatu</b>. Vyrų ir moterų grupės nebus atskiriamos!</p>

                            <p><b>DOUBLE ELIMINATION</b> - Standartinis dvigubo eliminavimo Cornhole turnyras yra labiausiai
                                paplitęs turnyro tipas. Ypač turnyruose, kuriuose dalyvauja didelis žaidėjų skaičius
                                arba yra ribotas laikas. Prieš iškrisdamas iš turnyro žaidėjas <b>gali pralaimėti 2 žaidimus</b>. Vieną kartą nugalėtojų ir vieną kartą pralaimėjusiųjų grupėje. Turnyras bus
                                žaidžiamas tol, kol tiek nugalėtojų grupėje, tiek pralaimėjusiųjų liks po 1 žaidėją.
                                Tada bus žaidžiamas finalas tarp nugalėtojų grupės žaidėjo ir pralaimėjusiųjų grupės
                                žaidėjo. Finalas bus žaidžiamas iki dviejų pergalių.</p>

                            <p>Turnyro metu vienam asmeniui numatomos <b>mažiausiai 2-ejos varžybos</b>. Kiekvienos varžybos
                                žaidžiamos iki surinkto 21 taško (privaloma dviejų taškų persvara). </p>

                        
                            <RulesImgWrapper>
                                <StaticImage src="../assets/images/turnyro-formatas-de.jpg" placeholder="none" quality="100" alt="board-cornhole-turnyras"/>
                            </RulesImgWrapper>

                            <h4>Varžybų taisyklės</h4>
                            <p>Varžybos bus organizuojamos pagal oficialias Cornhole taisykles. Visas jas rasite šioje
                                nuorodoje (esant klausimams galite susisiekti su varžybų organizatoriais nurodytais
                                kontaktais)</p>
                            <a href="https://mysqlvm.blob.core.windows.net/acl-docs/ACL-Rules-Regs-2020-2021.pdf" target="_blank" rel="noreferrer" big="true">Oficialios ACL Varžybų taisyklės</a>


                            <h4>Žaidimo taisyklės</h4>
                            <Link to="/taisykles">Bohabo Cornhole Žaidimo taisyklės</Link>

                            <h4>Video apie Cornhole:</h4>

                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/3ByOwj4dlEg"
                                    title="YouTube video player" frameBorder="0" loading="lazy"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>

                            <h4>#1 Bohabo Cornhole turnyro akimirkos:</h4>

                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/dr2tssIuQig"
                                    title="YouTube video player" frameBorder="0" loading="lazy"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>

                            <h4>#2 Bohabo Cornhole turnyro akimirkos:</h4> 
                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/ZR_y7JIrrY0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 

                            <h4>Papildoma informacija</h4>

                            <p>Varžybų metu ištroškusiems ir išalkusiems dalyviams bus galimybė įsigyti užkandžių bei gėrimų pačiame futbolo manieže esančioje kavinėje.
Varžybos vyks futbolo maniežo viduje ant dirbtinos žolės dangos. Reikalavimų aprangai ir avalynei nėra.</p>

                            <h4>Prizai</h4>

                            <p>Turnyro nugalėtojams išdalinsime rėmėjų įsteigtus prizus, kurių bendra vertė siekia net <b>500€!!!</b></p>

                            <h4>Rėmėjai</h4>

                            <p>Hummel, Bohabo Cornhole</p>

                            <h5>Platesnė informacija teikiama nurodytais kontaktais:</h5>

                            <ul style={{marginBottom: `3rem`}}>
                                <li><a href="tel:+37063653683">+370 636 52683</a></li>
                                <li><a href="mailto:info@bohabo.com">info@bohabo.com</a></li>
                            </ul>

                            <ButtonA href="https://forms.gle/Ns1dA1v8sbwz5Dgm9" target="_blank" big="true">Registracija į turnyrą</ButtonA>

                        </RulesText>

                    </RulesInfoWrapper>

                </PageContainer>
            </PageWrapper>
        </Layout>
    )
}

export default TournamentPage

const RulesInfoWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin-top: 0rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 4rem;
  }
`;

const RulesText = styled.div`
    max-width: 750px;
    margin: auto;
    
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
`;

const RulesImgWrapper = styled.div`
  grid-column: 1/-1;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }
`;