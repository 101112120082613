import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import {ButtonA} from "../styles/Button";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";

const TurnyrasConstant = () => {

    return (
        <PageContent>
            <RulesItems>
                <HeadingH2 >Bohabo Cornhole Žaidimo turnyras</HeadingH2>
                <Underline fluid primary small/>
                <RulesP><b>Gegužės 21 d. 14:00 val.</b>, šeštadienį Kauno futbolo maniežas, Aukštaičių g. 51, Kaunas<br/><br/>
                Turnyro nugalėtojams išdalinsime rėmėjų įsteigtus prizus, kurių bendra vertė siekia net <b>500€!!!</b></RulesP>
                <ButtonA href="https://forms.gle/Ns1dA1v8sbwz5Dgm9" target="_blank" big="true">Registracija į turnyrą</ButtonA>
            </RulesItems>
            <RulesImg>
                    <StaticImage src="../assets/images/bohabo-turnyras-03.jpg" placeholder="none" quality="100" alt="Bohabo Cornhole turnyro informacija"/>
            </RulesImg>
        </PageContent>
    );
};

export default TurnyrasConstant;

const RulesItems = styled.div`
    grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 1/7;
    text-align: left;
    padding-right: 5rem;
  }
`;

const RulesP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 21px);
  margin: 0 auto 2rem;
  max-width: 750px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 4rem 0 5rem;
  }
`;

const RulesImg = styled.div`
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 7/13;
    transform: translateX(4px);
    max-width: 100%;
  }
`;
